import "./inputField.scss";

const InputField = ({label, value, name, placeholder, type="text", handleChange, ...props}) => {

    return (
        <div>
          <p className="label">{label}</p>
          <input
            {...props}
            className="input"
            onChange={handleChange}
            name={name}
            placeholder={placeholder}
            value={value}
            type={type}
          ></input>
        </div>
    )
}

export default InputField;