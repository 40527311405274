import React from 'react';
import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  p {
    justify-content: center;
    display: flex;
    margin-top: 5px;
    margin-left: 5px;
    color: #A7A7A7;
    font-size: 14px;
  }
`;

export const HomeIcon = (props) => {
  const [hover, sethover] = React.useState('#A7A7A7');
  return (
    <Div>
      {' '}
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill={props.fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        onMouseEnter={() => sethover('#E83741')}
        onMouseLeave={() => sethover('#A7A7A7')}
      >
        <path
          d="M5.81906 13.0001H3.76733L13.0001 3.76733L22.2329 13.0001H20.1811"
          stroke={props.stroke || hover}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.81897 13V20.181C5.81897 20.7252 6.03513 21.247 6.41991 21.6318C6.80468 22.0166 7.32654 22.2328 7.87069 22.2328H18.1293C18.6735 22.2328 19.1953 22.0166 19.5801 21.6318C19.9649 21.247 20.181 20.7252 20.181 20.181V13"
          stroke={props.stroke || hover}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.92249 22.2328V16.0776C9.92249 15.5335 10.1386 15.0116 10.5234 14.6268C10.9082 14.242 11.4301 14.0259 11.9742 14.0259H14.0259C14.5701 14.0259 15.0919 14.242 15.4767 14.6268C15.8615 15.0116 16.0777 15.5335 16.0777 16.0776V22.2328"
          stroke={props.stroke || hover}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {props.hoverForParagraph && <p>Dashboard</p>}
    </Div>
  );
};
