import React, { useState } from 'react';
import styled from 'styled-components';
import { MenuToggle } from './menuToggle';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignInAlt,
  faPlusCircle,
  faUserAlt,
  faClipboard,
  faUser,
  faSignOutAlt,
  faUserTag,
  faBriefcase
} from '@fortawesome/free-solid-svg-icons';
import '../../assets/scss/_header.scss';
import auth from '../../auth';

const NavLinksContrainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
`;

const LinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  list-style: none;
  background-color: #fafafa;
  flex-direction: column;
  position: fixed;
  top: 70px;
  left: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
`;

const LinkItem = styled.li`
  width: 100%;
  padding: 15px 1.1em;
  color: #3e3e3e;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  border-bottom: 1px solid #eeeeee;
`;

const Link = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const FAContainer = styled.div`
  width: 20px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export function MobileNavLinks(props) {
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const urlDashboard = auth.isCompany()
    ? '/arbeitgeber-dashboard'
    : auth.isOrganization()
    ? '/organisations-dashboard'
    : '/bewerber-dashboard';

  return (
    <NavLinksContrainer>
      <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
      <LinksWrapper className={`${isOpen ? 'open-navbar' : ''}`}>
        <LinkItem>
          <Link onClick={() => history.push('/arbeitgeber')}>
            <FAContainer>
              <FontAwesomeIcon icon={faUser} />
            </FAContainer>
            <div>Für Arbeitgeber</div>
          </Link>
        </LinkItem>
        <LinkItem>
          <Link onClick={() => history.push('/bewerber')}>
            <FAContainer>
              <FontAwesomeIcon icon={faUserTag} />
            </FAContainer>
            <div>Für Bewerber</div>
          </Link>
        </LinkItem>
        <LinkItem>
          <Link onClick={() => history.push('/jobs')}>
            <FAContainer>
              <FontAwesomeIcon icon={faUserTag} />
            </FAContainer>
            <div>Jobs</div>
          </Link>
        </LinkItem>
        <LinkItem>
          <Link onClick={() => history.push('/beiträge')}>
            <FAContainer>
              <FontAwesomeIcon icon={faClipboard} />
            </FAContainer>
            <div>Beiträge</div>
          </Link>
        </LinkItem>
        <LinkItem>
          <Link onClick={() => history.push('/partner-und-kooperationen')}>
            <FAContainer>
              <FontAwesomeIcon icon={faBriefcase} />
            </FAContainer>
            <div> Partner & Kooperationen</div>
          </Link>
        </LinkItem>
        {auth.isAuthenticated() ? (
          <>
            <LinkItem>
              <Link onClick={() => auth.logout((_) => history.push('/login'))}>
                <FAContainer>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </FAContainer>
                <div>Logout</div>
              </Link>
            </LinkItem>
            <LinkItem>
              <Link onClick={() => history.push(urlDashboard)}>
                <FAContainer>
                  <FontAwesomeIcon icon={faUserAlt} />
                </FAContainer>
                <div>Dashboard</div>
              </Link>
            </LinkItem>
          </>
        ) : (
          <>
            <LinkItem>
              <Link onClick={() => history.push('/login')}>
                <FAContainer>
                  <FontAwesomeIcon icon={faSignInAlt} />
                </FAContainer>
                <div>Login</div>
              </Link>
            </LinkItem>
            <LinkItem>
              <Link onClick={() => history.push('/profil-anlegen')}>
                <FAContainer>
                  <FontAwesomeIcon icon={faPlusCircle} />
                </FAContainer>
                <div>Registrierung</div>
              </Link>
            </LinkItem>
          </>
        )}
      </LinksWrapper>
    </NavLinksContrainer>
  );
}
